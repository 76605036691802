<template>
<div class="ui-faqs-item" ref="ui_faq" @click="toggleOpenClose($event)">
    <div class="ui-faqs-title">
        <slot name="heading"></slot>
        <ui-button class="btn--primary ui-faqs-btn"><i class="fa fa-angle-down" aria-hidden="true"></i></ui-button>
    </div>
    <div class="ui-faqs-body" ref="ui_faq_body">
        <slot name="content"></slot>
    </div>
</div>
</template>

<script>
import UiButtonVue from './UiButton.vue'
import {
    toggle
} from 'slidetoggle';
export default {

    name: "ui-faq",
    components: {
        "ui-button": UiButtonVue
    },
    mounted() {
        this.faqBodyElm = this.$refs.ui_faq_body
    },
    data() {
        return {
            faqBodyElm: null,
        }
    },
    methods: {
        toggleOpenClose(event) {
            event.preventDefault();
            event.target.classList.toggle("__open");
            toggle(this.faqBodyElm, {
                miliseconds: 300,
                transitionFunction: 'ease-in',
            })
        },
    },

}
</script>

<style lang="scss">
@import "./src/assets/styles/settings.scss";
.ui-faqs-btn:focus,
.ui-faqs-item.__open .ui-faqs-btn {
    background-color: #4e5157;
}

.ui-faqs-item {
    margin-bottom: 16px;
    -webkit-transition: all .3s ease;
    transition: all .3s ease;
    //box-shadow: 2px 4px 10px 0px rgba(0, 0, 0, .12);
    border-bottom: 1px solid #39a8e3;
}

.ui-faqs-item:hover {
    cursor: pointer;
   // box-shadow: 4px 8px 10px 0px rgba(0, 0, 0, 0.082);
}

.ui-faqs-item.__open {
    //box-shadow: 4px 8px 10px 0px rgba(0, 0, 0, .12);
}

/***** -- main Accordion  --- **/
.ui-faqs-title {
    padding: 20px 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    font-weight: bold;
    font-size:20px;
        
}

.ui-faqs-body {
    padding: 4px 32px 32px;
    text-align: left;
    display: none;
    //transition: all 0.3 ease-in-out;
    overflow: hidden;
    font-size: 18px;
}

.ui-faqs-body.__open {
    display: block;
}

.ui-faqs-btn {
    width: 22px;
    min-width: 22px;
    height: 22px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50% !important;
    border: 0 !important;
    font-size: 23px;
    padding: 0 !important;
    -webkit-transition: all .3s ease !important;
    margin-left: 16px;
    cursor: pointer !important;

    i{
        line-height: inherit;
        color:#fff;
    }
}

.ui-faqs-item.__open .ui-faqs-btn {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
    background-color:$primary-color;
}

.ui-faqs-item.__open .ui-faqs-title {
    color:$primary-color;
}
.ui-faqs-item.__open .ui-faqs-title i{
    color:#222;
}

@media screen and (max-width: 767px) {
    .ui-faqs-title {
        padding: 20px 8px;
    }

    .ui-faqs-body {
        padding: 4px 8px 8px 24px;
    }
}

.ui-faqs-item > * {
  pointer-events: none;
}
</style>
