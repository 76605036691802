<template>
<section class="faq-section ui-section">
    <div class="ui-max-width">
        <ui-head class="txt-center">
            <template #head>
                <h3 class="faq-head" v-html="$t('faq_head')"></h3>
            </template>
        </ui-head>
        <ui-faq class="faq-comp">
            <template #heading>
                <h4 v-html="$t('faq1')"></h4>
            </template>
            <template #content>
                <p v-html="$t('faq1_a')"></p>
                <p v-html="$t('faq1_a1')"></p>
            </template>
        </ui-faq>
        <ui-faq class="faq-comp">
            <template #heading>
                <h4 v-html="$t('faq2')"></h4>
            </template>
            <template #content>
                <p v-html="$t('faq2_a')"></p>
            </template>
        </ui-faq>
        <ui-faq class="faq-comp">
            <template #heading>
                <h4 v-html="$t('faq3')"></h4>
            </template>
            <template #content>
                <p v-html="$t('faq3_a')"></p>
                <p v-html="$t('faq3_a2')"></p>
            </template>
        </ui-faq>
        <ui-faq class="faq-comp">
            <template #heading>
                <h4 v-html="$t('faq4')"></h4>
            </template>
            <template #content>
                <p v-html="$t('faq4_a2')"></p>
                <ul>
                    <li><p>{{$t('faq4_a2_l1')}}</p></li>
                    <li><p>{{$t('faq4_a2_l2')}}</p></li>
                    <li><p>{{$t('faq4_a2_l3')}}</p></li>
                    <li><p>{{$t('faq4_a2_l4')}}</p></li>
                    <li><p>{{$t('faq4_a2_l5')}}</p></li>
                </ul>
            </template>
        </ui-faq>
        <!--
        <ui-faq class="faq-comp">
            <template #heading>
                <h4 v-html="$t('faq5')"></h4>
            </template>
            <template #content>
                <p v-html="$t('faq5_a')"></p>
                <p v-html="$t('faq5_a2')"></p>
            </template>
        </ui-faq>
        -->
        <ui-faq class="faq-comp">
            <template #heading>
                <h4 v-html="$t('faq6')"></h4>
            </template>
            <template #content>
                <p v-html="$t('faq6_a')"></p>
            </template>
        </ui-faq>
        <ui-faq class="faq-comp">
            <template #heading>
                <h4 v-html="$t('faq7')"></h4>
            </template>
            <template #content>
                <p v-html="$t('faq7_a')"></p>
            </template>
        </ui-faq>
        <ui-faq class="faq-comp">
            <template #heading>
                <h4 v-html="$t('faq8')"></h4>
            </template>
            <template #content>
                <p v-html="$t('faq8_a')"></p>
            </template>
        </ui-faq>
        <ui-faq class="faq-comp">
            <template #heading>
                <h4 v-html="$t('faq9')"></h4>
            </template>
            <template #content>
                <p v-html="$t('faq9_a')"></p>
                <p v-html="$t('faq9_a2')"></p>
            </template>
        </ui-faq>
        <ui-faq class="faq-comp">
            <template #heading>
                <h4 v-html="$t('faq10')"></h4>
            </template>
            <template #content>
                <p v-html="$t('faq10_a')"></p>
            </template>
        </ui-faq>
        <ui-faq class="faq-comp">
            <template #heading>
                <h4 v-html="$t('faq11')"></h4>
            </template>
            <template #content>
                <p v-html="$t('faq11_a')"></p>
            </template>
        </ui-faq>
        <ui-faq class="faq-comp">
            <template #heading>
                <h4 v-html="$t('faq12')"></h4>
            </template>
            <template #content>
                <p v-html="$t('faq12_a')"></p>
            </template>
        </ui-faq>
        <ui-faq class="faq-comp">
            <template #heading>
                <h4 v-html="$t('faq13')"></h4>
            </template>
            <template #content>
                <p v-html="$t('faq13_a')"></p>
            </template>
        </ui-faq>
        <ui-faq class="faq-comp">
            <template #heading>
                <h4 v-html="$t('faq14')"></h4>
            </template>
            <template #content>
                <p v-html="$t('faq14_a')"></p>
            </template>
        </ui-faq>
        <ui-faq class="faq-comp">
            <template #heading>
                <h4 v-html="$t('faq15')"></h4>
            </template>
            <template #content>
                <p v-html="$t('faq15_a')"></p>
                <p v-html="$t('faq15_a2')"></p>
                <p v-html="$t('faq15_a3')"></p>
                <p v-html="$t('faq15_a4')"></p>
                <p v-html="$t('faq15_a5')"></p>
                <p v-html="$t('faq15_a6')"></p>
            </template>
        </ui-faq>
        <ui-faq class="faq-comp">
            <template #heading>
                <h4 v-html="$t('faq16')"></h4>
            </template>
            <template #content>
                <p v-html="$t('faq16_a')"></p>
            </template>
        </ui-faq>
        <ui-faq class="faq-comp">
            <template #heading>
                <h4 v-html="$t('faq17')"></h4>
            </template>
            <template #content>
                <p v-html="$t('faq17_a')"></p>
            </template>
        </ui-faq>
    </div>

</section>
</template>

<script>
import UiFaqVue from '../../components/UiFaq.vue'
import UiHeadingVue from '../../components/UiHeading.vue'
export default {

    components: {
        "ui-faq": UiFaqVue,
        "ui-head": UiHeadingVue
    }

}
</script>

<style lang="scss">

.faq-head {
    color: $primary-color;
}

.faq-section {
    background: $faq-section-bg-color;

    .ui-head {
        color: $primary-color;
    }

    .ui-faqs-item {
        &.__open {
            border-bottom-color: $primary-color;

            .ui-faqs-btn {
                i {
                    color: #fff;
                }
            }
        }
    }
}

.ui-faqs-item {
    border-bottom-color: $faq-hr-color;
}

.faq-comp {
    .ui-faqs-btn {
        background-color: $faq-btn-color;
    }
}
</style>
