<template>
    <a class="copyright__link" target="_blank" :href="'https://app.longboat.com/gateway/privacy?locale=' + locale" >
    <!-- @slot Use this slot to provide content of the button -->
    <slot></slot>
    </a>
</template>


<script>
/**
 * Displays privacy policy link for Longboat Apps
 * @displayName Privacy Policy Link
 */
export default {
    name: "privacy-policy-link",
    props: {
        /**
         * locale for this privacy policy link
         */
        locale: {
            type: String,
            required: true
        }
    }
}
</script>

<style lang="scss">
.copyright {
    &__link {
        text-decoration: none;
        color: inherit;

        &:active,
        &:hover,
        &:visited {
            color: inherit;
            text-decoration: none;
        }

        &:hover {
            text-decoration: underline;
        }
    }
}
</style>

<docs lang="md">
Displays Privacy policy link

## Examples

```jsx
<privacy-policy-link locale='en'>Privacy policy</privacy-policy-link>
```

</docs>