<template>
<div class="input-wrapper">
    <label class="ui-input__label" :for="name">
        <!-- @slot Use this slot to provide label of input -->
        <slot name="label"></slot>
    </label>
    <input class="ui-input" :type="inputType" :id="name" :name="name" v-model.trim="value" :placeholder="placeholder" @input="processInput" @keyup="$emit('keyupEvent', $event)">
    <div role="alert" class="input-alert-error" v-if="isErrorVisible && isError">
        <i class="input-error-icon fas fa-exclamation-circle"></i>
        <!-- @slot Use this slot to provide error message  -->
        <slot name="error-msg"></slot>
    </div>
</div>
</template>

<script>
/**
 * text input field
 * @displayName Text Input
 */
export default {
    name: "text-input",
    mounted() {
        this.processInput()
    },
    data() {
        return {
            value: '',
            isError: false,
            //isErrorVisible: false,
        }
    },
    props: {


        /**
         * validator for input
         */
        validator: {
            type: Function,
            required: false
        },

        /**
         * placeholder for input
         */
        placeholder: {
            type: String,
            required: true
        },

        /**
         * name of input
         */
        name: {
            type: String,
            required: false
        },

        inputType: {
            type: String,
            default: "text",
            required: false,
        },

        /**
         * name of input
         */
        isErrorVisible: {
            type: Boolean,
            required: false
        }
    },
    methods: {
        processInput: function(){
           if(this.validator == undefined){
               console.log("A validator is not defined")
           }else{
               this.isError = !this.validator(this.value);
           }
           this.$emit('inputValueEvent', this.value);
                
        }
    },
}
</script>

<style lang="scss">
.input-wrapper{
 text-align: left;
}
.ui-input {
    width: 100%;
    padding: 12px 20px;
    margin: 8px 0;
    display: inline-block;

    background: #F8F8F8;
    border: 1px solid #BABABA;
    box-sizing: border-box;
    border-radius: 4px;

    &:focus{
        outline: none;
       // background: #F8F8F8;
        border: 1px solid #007EA3;
        box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.15);
    }

    &__label{
        color:#007EA3;
    }
}

.input-alert-error {
    color: #B91C1C;
    font-style: italic;
    letter-spacing: 0.5px;
}

.input-error-icon{
 margin-right: 8px;
}
</style>


<docs lang="md">
Use this to display a text input

## Examples

```jsx
<text-input placeholder='Joe' name='firstname'>
    <template slot='label'>Your name</template>
    <template slot='error-msg'>Please provide a valid name</template>
</text-input>
```

</docs>