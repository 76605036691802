<template>
<div>

<section id="ui_si_v2" class="ui-si-v2-wrapper ui-section">
    <div class="ui-width-limit">
        <div class="ui-si-v2-flex">

            <div class="ui-si-v2-flex-item">
                <div class="ui-si-v2-icon">
                    <img id="img1" class="ui-sa-img" src="@/assets/images/duration.png" alt="">
                    <!-- <i aria-hidden="true" class="ui-sa-img far fa-building"></i> -->
                </div>
                <p id="p1" class="ui-si-v2-para">{{$t('info_duration')}}</p>
            </div>
            <div class="ui-si-v2-flex-item">
                <div class="ui-si-v2-icon">
                    <img id="img2" class="ui-sa-img" src="@/assets/images/participants.png" alt="">
                    <!-- <i aria-hidden="true" class="ui-sa-img fas fa-users"></i> -->
                </div>
                <p id="p2" class="ui-si-v2-para">{{$t('info_participants')}}</p>
            </div>
            <div class="ui-si-v2-flex-item">
                <div class="ui-si-v2-icon">
                    <img id="img3" class="ui-sa-img" src="@/assets/images/centers.png"  alt="">
                    <!-- <i aria-hidden="true" class="ui-sa-img fas fa-globe-americas"></i> -->
                </div>
                <p id="p3" class="ui-si-v2-para">{{$t('info_centers')}}</p>
            </div>

        </div>
    </div>
</section>
<section  class="ui-section __videos">
    <div class="ui-width-limit">
        <h2 class="ui-head __center">{{$t('video_text')}}</h2>
        <div class="ui-si-v2-flex">
 <!-- 
            <div class="ui-si-v2-flex-item">
                
               <p id="p1" class="ui-si-v2-para">{{$t('info_duration')}}</p>
                <img id="img1" class="ui-sa-img __alt" src="@/assets/images/vid-placeholder.png" alt="">
                <cn-video style="" :video="$t('video_one')" :videoTitle="$t('video_caption_one')" class=""></cn-video>
                
            </div>
            -->
            <div class="ui-si-v2-flex-item">
                
                <!-- <p id="p2" class="ui-si-v2-para">{{$t('info_participants')}}</p> -->
                <cn-video style="" :video="$t('video_two')" :videoTitle="$t('video_caption_two')"  class=""></cn-video>
              
            </div>


        </div>

        <span class="video-disclaimer"><i>{{ $t('video_disclaimer')}}</i></span>
    </div>
</section>
</div>
</template>

<script>
import UiVideo from "@/components/WistiaVideo.vue";
export default {
    components: {
        "cn-video": UiVideo,
    },
}
</script>

<style lang="scss">

.video-disclaimer{
    max-width: 800px;
    margin: 0 auto;
    font-size: 14px;
    font-style: italic;
    display: block;
   // margin-top: 16px;
    margin-bottom: 16px;
    text-align: center;
    color: #005172;
    line-height: 1.5
}

.ui-si-v2-icon {
    @include site-info-icon-bg;
    color: #fff;
}

.ui-si-v2-heading {
    color: $accent-color;
}

p.ui-si-v2-para {
    color: #fff;
}

/*EDIT COLOURS HERE*/
.ui-section.__videos{
background-color: #f5f5f5;
}
.ui-width-limit {
    max-width: 1280px;
    margin: 0 auto;
}

.ui-si-v2-wrapper {
    @include site-info-wrap-bg;
}
.ui-head.__center{
    text-align: center;
}
.ui-si-v2-flex {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
   // justify-content: space-between;
    justify-content: space-evenly;

    -ms-flex-wrap: wrap;
    flex-wrap: wrap;

    @media only screen and (max-width: 767px) {
        flex-direction: column;
        align-items: center;
    }
}

.ui-si-v2-flex-item {
    margin: 16px 24px;
    padding: 16px;
    -ms-flex-preferred-size: 25%;
    flex-basis: 25%;
    position: relative;
    &.__alt{
        flex-basis: 40%;
    }

   
}

.ui-si-v2-icon {
    width: 110px;
    height: 110px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin: 0 auto;
    border-radius: 50%;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-box-shadow: 1px 1px 13px 4px rgba(0, 0, 0, .08);
    box-shadow: 1px 1px 13px 4px rgba(0, 0, 0, .08);
    margin-bottom: 24px;
}

.ui-si-v2-icon img {
    max-height: 73px;
    max-width: 73px;
}

.ui-si-v2-heading {
    font-size: 38px;
    font-style: normal;
    font-weight: 400;
    line-height: 45px;
    letter-spacing: 1px;
    text-align: center;
    margin-bottom: 8px;
}

p.ui-si-v2-para {
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 2px;
    text-align: center;
    text-transform: uppercase;
    margin-bottom: 0;
}

@media only screen and (max-width: 1023px) {
    .ui-si-v2-flex-item {
        margin: 16px 8px;
        padding: 8px;
        flex-basis: 28%;
    }
}

@media only screen and (max-width: 767px) {
    .ui-si-v2-flex-item {
        -ms-flex-preferred-size: 100%;
        flex-basis: 100%;
        margin: 0 0 48px;
    }

    .ui-si-v2-wrapper {
        padding: 80px 16px 32px;
    }
}

.ui-sa-img {
    font-size: 3em;
}
.ui-sa-img.__alt {
    max-width: 100%;
        border: 1px solid #b6b6b6;
}
</style>
