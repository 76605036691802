<template>
<div>
    <div class="study-member-v2">
        <img class="study-member-v2__image" :src="require(`@/assets/images/team/${image}`)" :alt="alt">
        <div class="study-member-v2__info">
            <slot></slot>
        </div>
    </div>

</div>
</template>

<script>
export default {
    props: {
        image: {
            type: String,
            required: false
        },
        alt: {
            type: String,
            required: false
        }
    }
}
</script>

<style lang="scss">
.study-member-v2 {

    display: flex;
    flex-direction: column;
    margin-bottom: 32px;

    @media screen and (min-width: 1000px) {
        flex-direction: row;
    }

    &__image {
        width: 100%;
        max-width: 120px;
        align-self: flex-start;
    }

    &__info {
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 100%;
        margin-left: 8px;
      //  max-width: 200px;

        @media screen and (min-width: 1000px) {
            
        }
    }
}
</style>
