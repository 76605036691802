<template>
<div>
    <img class="study-member__image" :src="require(`@/assets/images/team-core/${image}`)" :alt="alt">
    <slot></slot>
</div>
</template>

<script>
export default {
    props: {
        image: {
            type: String,
            required: false
        },
        alt: {
            type: String,
            required: false
        }
    }
}
</script>

<style>
.study-member__image {
    width: 100%;
    max-width: 200px;
}
</style>
