<template>
<section class="ui-section">
    <div class="ui-max-width">
        <ui-head class="txt-center">
            <template #head>
                <span v-html="$t('study_team_head')"></span>
            </template>
        </ui-head>

        <!-- Core Team-->
        <div style="margin-bottom: 80px; padding: 40px;">
            <h2 class="sub-head" v-html=" $t('study_team_h')"></h2>
            <div class="ui-vsbs-item-wrap">
                <div class="ui-vsbs-item">
                    <study-member image="brahm.jpg">
                        <h3 v-html="$t('member1_name')"></h3>
                        <span v-html="$t('member1_details')"></span>
                    </study-member>
                </div>
                <div class="ui-vsbs-item">
                    <study-member image="steven.jpg">
                        <h3 v-html="$t('member2_name')"></h3>
                        <span v-html="$t('member2_details')"></span>
                    </study-member>
                </div>
                <div class="ui-vsbs-item">
                    <study-member image="amy.jpg">
                        <h3 v-html="$t('member3_name')"></h3>
                        <span v-html="$t('member3_details')"></span>
                    </study-member>
                </div>

                <div class="ui-vsbs-item">
                    <study-member image="jeremy.jpg">
                        <h3 v-html="$t('member4_name')"></h3>
                        <span v-html="$t('member4_details')"></span>
                    </study-member>
                </div>
                <div class="ui-vsbs-item">
                    <study-member image="julia.jpg">
                        <h3 v-html="$t('member5_name')"></h3>
                        <span v-html="$t('member5_details')"></span>
                    </study-member>
                </div>
                <div class="ui-vsbs-item">
                    <study-member image="jay.jpg">
                        <h3 v-html="$t('member6_name')"></h3>
                        <span v-html="$t('member6_details')"></span>
                    </study-member>
                </div>
            </div>
        </div>

        <!-- CIS Team -->
        <div style="background-color: rgb(251, 251, 251);padding: 40px;">
            <h2 class="sub-head">{{ $t('study_team_h2')}}</h2>
            <div class="ui-vsbs-item-wrap-v2">
                <div class="ui-vsbs-item-v2">
                    <study-member2 image="jan.jpg">
                        <h3 v-html="$t('member7_name')"></h3>
                        <span v-html="$t('member7_details')"></span>
                    </study-member2>
                </div>
                <div class="ui-vsbs-item-v2">
                    <study-member2 image="john.jpg">
                        <h3 v-html="$t('member8_name')"></h3>
                        <span v-html="$t('member8_details')"></span>
                    </study-member2>
                </div>

                <div class="ui-vsbs-item-v2">
                    <study-member2 image="bellal.jpg">
                        <h3 v-html="$t('member9_name')"></h3>
                        <span v-html="$t('member9_details')"></span>
                    </study-member2>
                </div>

                <div class="ui-vsbs-item-v2">
                    <study-member2 image="shannon.jpg">
                        <h3 v-html="$t('member10_name')"></h3>
                        <span v-html="$t('member10_details')"></span>
                    </study-member2>
                </div>

                <div class="ui-vsbs-item-v2">
                    <study-member2 image="karen.jpg">
                        <h3 v-html="$t('member11_name')"></h3>
                        <span v-html="$t('member11_details')"></span>
                    </study-member2>
                </div>

                <div class="ui-vsbs-item-v2">
                    <study-member2 image="sarah.jpg">
                        <h3 v-html="$t('member12_name')"></h3>
                        <span v-html="$t('member12_details')"></span>
                    </study-member2>
                </div>

                <div class="ui-vsbs-item-v2">
                    <study-member2 image="tanner.jpg">
                        <h3 v-html="$t('member13_name')"></h3>
                        <span v-html="$t('member13_details')"></span>
                    </study-member2>
                </div>
            </div>
        </div>

    </div>
</section>
</template>

    
    
<script>
import UiHeadingVue from '../../components/UiHeading.vue'
import StudyMember from '../../components/StudyMember.vue'
import StudyMember2 from '../../components/StudyMember2.vue'
export default {
    components: {
        "ui-head": UiHeadingVue,

        "study-member": StudyMember,
        "study-member2": StudyMember2
    }
}
</script>
    
    
<style scoped>
/*EDIT COLOURS HERE*/
.ui-ts-heading {
    color: #4e5157;
}

.ui-ts-heading span {
    color: #39a8e3;
}

.ui-ts-subheading {
    color: #39a8e3;
}

.ui-ts-subheading {
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 26px;
    letter-spacing: 0.005em;
    text-align: left;
    margin-bottom: 8px;
}

.ui-ts-text-wrap {
    margin-left: 0;
}

.ui-ts-ul {
    list-style: disc;
    margin-top: 12px;
    margin-bottom: 12px;
}

.ui-ts-ol {
    list-style: decimal;
    margin-top: 12px;
    margin-bottom: 12px;
}

.ui-ts-li {
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: left;
    margin-bottom: 6px;
    padding-left: 8px;
}

.abt-btn {
    color: white;
}
</style>
<style>
.sub-head{
    text-align: center;
    font-size: 19px;
}
    /*EDIT COLOURS HERE*/
    .ui-vsbs-title {
        color: #4799ce;
    }

    .ui-vsbs-item {
        flex: 0 0 25%;
    }

    a.ui-vsbs--button {
        background-color: #38a8e3;
        color: #fff;
    }

    a.ui-vsbs--button:hover {
        background-color: #79858F;
        color: #fff;
    }

    /*EDIT COLOURS HERE*/
    .ui-width-limit {
        max-width: 1280px;
        margin: 0 auto;
    }

    .ui-vsbs-wrap {
        background-color: #fff;
        padding: 50px 16px;
    }

    .ui-vsbs-item-wrap {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        flex-wrap: wrap;
        padding: 16px 0 40px 0;
    }

    .ui-vsbs-item-wrap-v2 {
        display: flex;
        flex-wrap: wrap;
        padding: 40px 0;
    }

    .ui-vsbs-item-v2 {
        width: 49%;
        margin-right: 8px;
    }

    @media only screen and (max-width: 500px) {
        .ui-vsbs-item-v2 {
            width: 100%;
        }
    }

    .ui-vsbs-title {
        font-size: 24px;
        letter-spacing: .35px;
        line-height: 31px;
        font-weight: 400;
        margin-bottom: 10px;
        text-align: center;
    }

    .ui-vsbs-video-wrap {
        width: 100%;
    }

    .ui-vsbs-text-wrap {
        -webkit-box-flex: 2;
        -ms-flex-positive: 2;
        flex-grow: 2;
        margin-bottom: 16px;
        text-align: center
    }

    .ui-vsbs-item {
        text-align: center;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 10px;
        box-sizing: border-box;
        margin-bottom: 32px;
    }

    p.ui-vsbs-para {
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0.01em;
        text-align: left;
        margin-bottom: 8px;
        color: #6B787C;
    }

    a.ui-vsbs--button {
        border-radius: 4px;
        cursor: pointer;
        display: inline-block;
        padding: 8px 20px;
        position: relative;
        text-decoration: none;
        -webkit-transition: all .3s ease;
        transition: all .3s ease;
        vertical-align: middle;
        white-space: nowrap;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
        letter-spacing: 1px;
        text-align: center;
        border: none;
        -webkit-box-shadow: 2px 4px 10px 0px rgba(0, 0, 0, 0.15);
        -moz-box-shadow: 2px 4px 10px 0px rgba(0, 0, 0, 0.15);
        box-shadow: 2px 4px 10px 0px rgba(0, 0, 0, 0.15);
        margin-top: 8px;
    }

    a.ui-vsbs--button:hover,
    a.ui-vsbs--button:visited,
    a.ui-vsbs--button:active {
        text-decoration: none;
    }

    .ui-vsbs--button+.ui-vsbs--button {
        margin-left: 8px;
    }

    @media only screen and (max-width: 1024px) {

        .ui-vsbs-item-wrap {
            flex-wrap: wrap;
        }

        .ui-vsbs-title {
            font-size: 22px;
        }
    }

    @media only screen and (max-width: 767px) {
        .ui-vsbs-item {
            flex: 0 0 50%;
        }
    }

    @media only screen and (max-width: 500px) {
        .ui-vsbs-item {
            flex: 0 0 100%;
        }
    }

    @media only screen and (max-width: 768px) {
        .ui-vsbs-item-v2 {
            width: 100%;
        }
        .study-member-v2{
            flex-direction: row;
        }
    }
</style>
