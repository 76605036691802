import Vue from "vue";
import {
    isPageAnimationEnabled,
} from "@/config";


export const consent = {
    status: false,
    setStatus(_status) {
        this.status = _status;
    },
}


export let resizeOverlay = function(elementID) {
    resize();

    function resize() {
        let element = document.getElementById(elementID);
        if (element !== null) {
            element.style.height = window.innerHeight + "px";
        }
    }

    window.addEventListener(
        "resize",
        function() {
            resize();
        },
        true
    );
}

export let wistiaCookieTracking = function(consent) {
    window._wq = window._wq || [];
    window._wq.push(function(W) {
        W.consent(consent);
        //console.log("Wistia: In privacy mode?", !W.consent()); // returns true or false
    });
}


let playAnimation = function(idElement, elementSelector) {
    let waitTimeInSecs = 15;
    setTimeout(function() {
        var element = document.getElementById(idElement);
        element.classList.remove(elementSelector);
    }, waitTimeInSecs * 60);
}

export let configurePageAnimation = function() {
    if (isPageAnimationEnabled && screen.width > 1000) {
        // disable page animation in screens 1000px and above
        let element = document.getElementById("app");
        element.classList.add("animate");
        playAnimation("app", "animate");
    }
}



export let configureConsent = function(consent) {
    if (Vue.$cookies.get('CNcookies') === "false") {
        consent.setStatus(false);
    }
    if (Vue.$cookies.get('CNcookies') === "true") {
        consent.setStatus(true);
    }
}

let addlocaleToUrl = function(context, locale) {
    context.$router.push({
            path: window.location.pathname,
            query: {
                lang: locale
            }
        },
        () => {}
    ); // the second .push value "() => {}" prevents the error message on console
}

export let handleLocaleChangeEvent = function(context, locale) {
    addlocaleToUrl(context, locale);
    if (consent.status) {
        console.log("this is the route: " + context.$route.fullPath);
    }
}

export let removeScript = function(scriptId) {
    console.log("removing script")
    let elem = document.getElementById(scriptId);
    return elem.parentNode.removeChild(elem);
}

export let addScript = function(_src, id, callback) {
    let script = document.createElement('script');
    script.src = _src;
    script.id = id
    script.async = true;
    document.body.appendChild(script);
    script.addEventListener("load", function() {
        console.log("script loaded :)");
        callback();
    });
}


export function getPreferredLocales(locales, localeGroup) {
    let localeGroupNew = {};
    locales.forEach(element => {
        for (const locale in localeGroup) {
            if (locale == element)
                localeGroupNew[locale] = localeGroup[locale]
        }
    });

    return localeGroupNew
}

export let setPageTitle = (title) => {
    document.title = title;
}

export let setChildHeightToParentHeight = function(parentID, childID) {
    let isEventRegistered = false;
    resize();

    function resize() {
        let parent = document.getElementById(parentID);
        let child = document.getElementById(childID);

        if (child !== null) {
            child.style.height = parent.clientHeight + "px";
        }
    }

    if (!isEventRegistered) {
        window.addEventListener(
            "resize",
            function() {
                resize();
                isEventRegistered = true;
            },
            true
        );
    }

}

/*
export const getSupportedLocales = (supportedLocales) => {
    let locales = {
        es: "Español",
        bd: "Belgisch-Nederlands",
        ck: "傳統中文",
        cs: "Čeština",
        ct: "繁體中文",
        de: "Deutsch",
        el: "ελληνικα",
        fc: "Français canadien",
        fr: "Français",
        hu: "Magyar",
        it: "Italiano",
        ko: "한국어",
        lp: "Español latinoamericano",
        ms: "Melayu",
        nl: "Nederlands",
        pl: "Polski",
        pt: "Português",
        ro: "Română",
        ru: "Русский",
        sp: "Español-EE.UU",
        th: "ไทย",
        zh: "简体中文",
    }
    let _supportedLocales = supportedLocales; //[{en: 'English'}]

    for (let [key, value] of Object.entries(yourobject)) {
        console.log(key, value);
    }

}
*/