<template>
<div>
    <site-menu @localeHasChangedEvent="reloadSections"></site-menu>
    <site-banner id="js-home"></site-banner>
    <about-study id="js-about"></about-study>
    <study-info></study-info>
    <study-center-map id="js-center" :key="mapKey"></study-center-map>
    <timeline-section id="js-timeline"></timeline-section>
    <study-team id="js-study-team"></study-team>
    <faq-section id="js-faqs"></faq-section>
    <share-study id="js-share"></share-study>
    <copyright-label class="copyright" :locale="$i18n.locale"></copyright-label>
    <aside>
        <cookie-modal :locale='$i18n.locale' v-on:consentAccepted="acceptConsent">
            <template #body-content> {{ $t("c_text") }} <br /> {{ $t("c_text_two") }}</template>
            <template #privacy-policy-link-text>{{ $t("c_privacy") }}.</template>
            <template #accept-btn-text>{{$t('c_accept')}}</template>
            <template #reject-btn-text>{{$t('c_nocookie')}}</template>
        </cookie-modal>
    </aside>
</div>
</template>

<script>
import CookieModal from "@/components/CookieModal.vue";
import {consent, wistiaCookieTracking} from "@/common/utils";
import auth from "@/plugins/auth";
import UiMenuVue from './partials/SiteMenu.vue'
import FaqSectionVue from './partials/FaqSection.vue'
import StudyInfoVue from './partials/StudyInfo.vue'
import StudyCenterVue from './partials/StudyCenter.vue'
import TimelineSectionVue from './partials/TimelineSection.vue'
import SiteBannerVue from './partials/SiteBanner.vue'
import AboutStudyVue from './partials/AboutStudy.vue'
import ShareStudyVue from './partials/ShareStudy.vue'
import CopyRightLabelVue from '../components/CopyRightLabel.vue'
import StudyTeam from './partials/StudyTeam.vue' 
       

export default {

    components: {
        "study-center-map": StudyCenterVue,
        "site-menu": UiMenuVue,
        "site-banner": SiteBannerVue,
        "study-team": StudyTeam,
        "faq-section": FaqSectionVue,
        "timeline-section": TimelineSectionVue,
        "study-info": StudyInfoVue,
        "about-study": AboutStudyVue,
        "share-study": ShareStudyVue,
        "copyright-label": CopyRightLabelVue,
        "cookie-modal": CookieModal,
    },
    data() {
        return {
            mapKey: "hgjjf",
            eligSecKey: "bklof"
        }
    },
    mounted() {
        document.title = this.$t('site_title');
    },

    methods: {
        reloadMap(){
               this.mapKey = this.mapKey + new Date().getSeconds() ;
        },

        reloadEligibilitySection(){
               this.eligSecKey = this.eligSecKey + new Date().getSeconds() ;
        },
        reloadSections(){
            this.reloadMap();
            this.reloadEligibilitySection()
        },
        login() {
            auth.login(this, this.password);
        },
        acceptConsent() {
            consent.setStatus(true);
            //  console.log("Login::consent status is: " + consent.status)
            wistiaCookieTracking(true);
        }
    },

}
</script>

<style lang="scss" scoped>
.copyright {
    padding: 16px;
    background: #4E5157;
    color: white;
    text-align: center;
}
</style>
