<template>

<section class="ui-section">
    
    <div class="ui-max-width">
        <div class="ui-ats-flex">
            <div class="ui-ats-flex-item __first">
                <ui-head class="txt-center">
                    <template #head>{{$t('abouthead') }}</template>
                </ui-head>
            </div>
            <div class="ui-ats-flex-item">
                <div class="ui-ts-text-wrap">
                    <p>{{$t('about1') }}</p>
                    <p>{{$t('about2') }}</p>
                    <p>{{$t('about3') }}</p>
                    <p>{{$t('about4') }}</p>
                    <p>{{$t('about5') }}</p>
                    <p>{{$t('about6') }}</p>
                    <p>{{$t('about7') }}</p>
                    <p>{{$t('about8') }}</p>
                    <p>{{$t('about9') }}</p>
                    <p>{{$t('about10') }}</p>
                    <br />
                </div>
            </div>
        </div>
    </div>
</section>
</template>

<script>
import UiHeadingVue from '../../components/UiHeading.vue'
export default {
 components: {
        "ui-head": UiHeadingVue
    }
}

</script>


<style scoped lang="scss">
.ui-ats-flex {
    display: flex;
    align-content: center;
    justify-content: space-between;
}
.ui-ats-flex-item {
    flex-basis: 60%;
}

.ui-ats-flex-item.__first {
    flex-basis: 35%;
    border-right: 3px solid $primary-color;
}
/*EDIT COLOURS HERE*/
.ui-ts-heading {
    color: #4e5157;
}

.ui-ts-heading span {
    color: #39a8e3;
}

.ui-ts-subheading {
    color: #39a8e3;
}

.ui-ts-subheading {
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 26px;
    letter-spacing: 0.005em;
    text-align: left;
    margin-bottom: 8px;
}

.ui-ts-text-wrap {
    margin-left: 0;
}

.ui-ts-ul {
    list-style: disc;
    margin-top: 12px;
    margin-bottom: 12px;
}

.ui-ts-ol {
    list-style: decimal;
    margin-top: 12px;
    margin-bottom: 12px;
}

.ui-ts-li {
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: left;
    margin-bottom: 6px;
    padding-left: 8px;
}
@media only screen and (max-width: 767px) {
    .ui-ats-flex {
        flex-wrap: wrap;
    }
.ui-ats-flex-item {
    flex-basis: 100%;
}

.ui-ats-flex-item.__first {
    flex-basis: 100%;
    border-right: none;
}
}
</style>
