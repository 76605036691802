<template>
<section class="tim-section ui-section">
    <div class="ui-max-width">
        <ui-head class="txt-center">
            <template #head>
                <h2 class="ui-head" v-html="$t('timeline_head')"></h2>
            </template>
            <template #subhead>{{$t('timeline_p')}}</template>
        </ui-head>
        <ui-timeline class="ui-timeline" :ImageSrc="require(`@/assets/images/screening.svg`)">
            <template #title>{{$t('timeline_stage1')}}</template>
            <template #content>
                <p>{{$t('timeline_stage1_p')}}</p>
                <p>{{$t('timeline_stage1_pa')}}</p>
            </template>
        </ui-timeline>

        <ui-timeline class="ui-timeline" :ImageSrc="require(`@/assets/images/treatment.svg`)">
            <template #title>{{$t('timeline_stage2')}}</template>
            <template #content>
                <p>{{$t('timeline_stage2_p')}}</p>
                <ul>
                    <li>{{$t('timeline_stage2_pb')}}</li>
                    <li>{{$t('timeline_stage2_pc')}}</li>
                </ul>
                <p>{{$t('timeline_stage2_pd')}}</p>
                <p>{{$t('timeline_stage2_pe')}}</p>
            </template>
        </ui-timeline>

        <ui-timeline class="ui-timeline" :ImageSrc="require(`@/assets/images/followup.svg`)">
            <template #title>{{$t('timeline_stage3')}}</template>
            <template #content>
                <p>{{$t('timeline_stage3_p')}}</p>
            </template>
        </ui-timeline>
    </div>

</section>
</template>

<script>
import UiHeadingVue from '../../components/UiHeading.vue'
import UiTimelineVue from '../../components/UiTimeline.vue'

export default {
    components: {
        'ui-timeline': UiTimelineVue,
        "ui-head": UiHeadingVue
    }
}
</script>

<style lang="scss">

.tim-section{
    background-color: $tim-section-bg-color;
}

.ui-timeline  .ui-tim-img-wrap  {
        background-color: $timline-img-bg-color;
}
</style>
