<template>
<div class="ui-tim-item">
    <div class="ui-tim-title-wrap">
        <slot name="title"></slot>
        <div class="ui-tim-img-wrap">
            <img  class="ui-tim-img" :src="ImageSrc" alt="">
        </div>
    </div>
    <div class="ui-tim-text-wrap">
        <slot name="content"></slot>
    </div>
</div>
</template>

<script>

export default {
    props: {
		ImageSrc: {
			type: String,
            required: false
		}
	}
}
</script>

<style>
	/*EDIT COLOURS HERE*/

	.ui-tim-img-wrap {
		background-color: #39a8e3;
	}

	.ui-tim-text-wrap {
		border-left: 2px solid #A8B1BD;
	}

	.ui-tim-item {
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-align: start;
		-ms-flex-align: start;
		align-items: flex-start;
		margin-bottom: 32px;
		align-items: flex-start;
    justify-content: center;
	}

	.ui-tim-text-wrap {
		width: 60%;
		padding-left: 16px;
		text-align: left;
	}

	.ui-tim-title-wrap {
		/*width: 40%;*/
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-pack: end;
		-ms-flex-pack: end;
		justify-content: flex-end;
		-webkit-box-align: center;
		-ms-flex-align: center;
		align-items: center;
		min-width: 25%;
		font-weight: 800;
	}


	.ui-tim-img-wrap {
		width: 70px;
		min-width: 70px;
		height: 70px;
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-pack: center;
		-ms-flex-pack: center;
		justify-content: center;
		-webkit-box-align: center;
		-ms-flex-align: center;
		align-items: center;
		border-radius: 50%;
		text-align: center;
		margin-left: 16px;
		margin-right: 16px;
	}


	.ui-tim-img {
		max-width: 53px;
	}


	@media screen and (max-width: 767px) {
		.ui-tim-item {
			-ms-flex-wrap: wrap;
			flex-wrap: wrap;
			margin-bottom: 48px;
		}

		.ui-tim-title-wrap,
		.ui-tim-text-wrap {
			width: 100%;
			padding-left: 0;
			border-left: 0;
		}

		.ui-tim-title-wrap {
			-webkit-box-orient: horizontal;
			-webkit-box-direction: reverse;
			-ms-flex-direction: row-reverse;
			flex-direction: row-reverse;
			-webkit-box-pack: end;
			-ms-flex-pack: end;
			justify-content: flex-end;
			margin-bottom: 16px;
		}

		.ui-tim-img-wrap {
			margin-left: 0;
		}
	}
</style>
