<template>
<div>
    <div id="js-map-wrapper" class="map-wrapper">
        <div v-if="isGMapOverlayVisible" id="js-gmap-overlay" class="map-overlay">
            <div class="map-overlay-wrapper">
                <p class="map-overlay-wrapper__text" v-html="$t('gmap_consent_text')"></p>
                <centered-layout class="">
                    <ui-button @click="processGMapConsent()" class="btn--primary gmap-consent-btn">
                        {{ $t("gmap_consent_button") }}
                    </ui-button>
                </centered-layout>
            </div>
        </div>
        <div class="map-details-wrapper">
            <div class="map-details">
                <ui-head :SubHeadAlign="'__left'">
                    <template #head>
                        <h3 v-html="$t('smap_head')"></h3>
                    </template>
                    <template #subhead>
                        {{$t('smap_p')}}
                    </template>
                </ui-head>
                <div class="ui-sdc">
                    <text-input ref="ui_url" class="ui-sdc__input" :placeholder="$t('find_a_site_input_placeholder')" @inputValueEvent="setSearchInputValue" @keyupEvent="processEnterKey"></text-input>
                    <ui-button ref="searchSiteBtn" class="find-center-btn btn--primary" @click="searchAddress(searchTerm)">{{$t('find_a_site_btn')}}</ui-button>
                </div>
            </div>
        </div>
        <div class="google-map" id="map" ref="googleMap"></div>
    </div>

    <modal-layout v-if="isSiteSearchErrorAlertVisible">
        <alert-layout @closeAlertEvent='isSiteSearchErrorAlertVisible = false'>
            <template #title>{{ $t('smap_alert') }}</template>
            <template #body>{{ $t('smap_alert_p') }}</template>
        </alert-layout>
    </modal-layout>
</div>
</template>

<script>
import {
    getSites,
    googleMapUrl,
    gMapOptions,
    gMapConsentCookieExpiryTimeInMins,
    gMapConsentCookieKey as _gMapConsentCookieKey
} from "@/config";
import {
    removeScript,
    addScript,
    setChildHeightToParentHeight
} from "@/common/utils";
import Cookies from 'js-cookie'
import UiButtonVue from '../../components/UiButton.vue';
import UiHeadingVue from '../../components/UiHeading.vue'
import TextInputVue from '../../components/form/TextInput.vue';
import ModalLayoutVue from '../../components/layout/ModalLayout.vue';
import AlertLayoutVue from '../../components/layout/AlertLayout.vue';
import CenteredLayoutVue from "../../components/layout/CenteredLayout.vue";

export default {
    name: "study-center",
    components: {
        "ui-button": UiButtonVue,
        "ui-head": UiHeadingVue,
        'text-input': TextInputVue,
        "modal-layout": ModalLayoutVue,
        "alert-layout": AlertLayoutVue,
        "centered-layout": CenteredLayoutVue
    },
    data() {
        return {
            isSiteSearchErrorAlertVisible: false,
            map: null,
            searchBtnElm: null,
            searchTerm: "",
            marker_list: [],
            mapOptions: gMapOptions,
            isGMapOverlayVisible: true,
            mapUrl: googleMapUrl + '&language=' + this.$t('gmap_locale'),
        };
    },

    mounted() {
        if (this.isGmapConsentCookieValid()) {
            this.loadMapScript(this.mapUrl, "gmapScript", this.loadMap);
            this.isGMapOverlayVisible = false;
        } else {
            var gmapOverlay = document.getElementById("map");
            gmapOverlay.classList.add("gmap-bg");
        }
        setChildHeightToParentHeight("js-map-wrapper", "js-gmap-overlay")
        this.searchBtnElm = this.$refs['searchSiteBtn']
    },

    methods: {
        isGmapConsentCookieValid() {
            if (Cookies.get(_gMapConsentCookieKey) !== undefined) {
                return true;
            }
            return false

        },
        processGMapConsent() {
            this.enableGMap()
            this.isGMapOverlayVisible = false;
        },
        /*
        removeMapOverlay() {
            var gmapOverlay = document.getElementById("js-gmap-overlay");
            gmapOverlay.classList.add("ui-hide");
        },
        */
        setGMapCookie(gMapConsentCookieKey) {
            Cookies.set(gMapConsentCookieKey, null, {
                expires: gMapConsentCookieExpiryTimeInMins / (24 * 60)
            })
        },
        enableGMap() {
            this.setGMapCookie(_gMapConsentCookieKey)
            this.loadMapScript(this.mapUrl, "gmapScript", this.loadMap);
        },
        processEnterKey(event) {
            if (event.keyCode === 13) {
                event.preventDefault();
                console.log(this.searchBtnElm)
                this.searchBtnElm.$el.click();
            }
        },
        setSearchInputValue(value) {
            this.searchTerm = value;
        },

        loadMap: function () {
            this.map = new window.google.maps.Map(
                this.$refs.googleMap, null
            );

            this.marker_list = getSites(window.google, this.map);
            let marker_data_list = [];
            for (let marker of this.marker_list) {

                if (marker.active == true) {
                    marker_data_list.push(
                        `<div id='content'>
                            <h2 class='gm-heading'> ${ marker.title } </h2>
                            <div id='bodyContent'>
                                <p><b><i class="fa fa-home"></i></b> ${ marker.address }</p>
                                <!--<p><b><i class="fa fa-envelope"></i></b> ${ marker.email }</p>-->
                            </div>
                        </div>`
                    );
                } else {
                    `<div id='content'>Site is not active</div>`
                }

            }

            // FIT DISPLAY TO CONTAIN ALL MARKERS
            var bounds = new window.google.maps.LatLngBounds();
            this.marker_list.forEach((marker, i) => {
                bounds.extend(marker.getPosition());
                this.attachMarkerMessage(marker, marker_data_list[i]);
            });

            this.map.fitBounds(bounds);
            this.map.setOptions(this.mapOptions)
        },

        loadMapScript(_src, id, callback) {

            let script = document.getElementById(id)

            if (script !== null) {
                removeScript(id);
            }

            addScript(_src, id, callback)
        },

        findNearestMarker: function (lat, lng) {
            var R = 6371; // radius of earth in km
            var distances = [];
            var closest = -1;
            for (let i = 0; i < this.marker_list.length; i++) {
                var mlat = this.marker_list[i].position.lat();
                var mlng = this.marker_list[i].position.lng();
                var dLat = this.rad(mlat - lat);
                var dLong = this.rad(mlng - lng);
                var a =
                    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
                    Math.cos(this.rad(lat)) *
                    Math.cos(this.rad(lat)) *
                    Math.sin(dLong / 2) *
                    Math.sin(dLong / 2);
                var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
                var d = R * c;
                distances[i] = d;
                if (closest == -1 || d < distances[closest]) {
                    closest = i;
                }
            }

            return closest;
        },

        searchAddress: function (searchTerm) {
            // DISTANCE AND NEAREST MARKER SELECTOR
            var geocoder = new window.google.maps.Geocoder();
            var lat = "";
            var lng = "";

            geocoder.geocode({
                    address: searchTerm,
                },
                (results, status) => {
                    if (status === window.google.maps.GeocoderStatus.OK) {
                        lat = results[0].geometry.location.lat();
                        lng = results[0].geometry.location.lng();
                        var nearest_marker = this.marker_list[this.findNearestMarker(lat, lng)];
                        this.map.panTo(nearest_marker.getPosition());
                        this.map.setZoom(6);
                        nearest_marker.infowindow.open(
                            nearest_marker.get("map"),
                            nearest_marker
                        );
                    } else {
                        /*
                        alert(
                            "Geocode was not successful for the following reason: " + status
                        );
                        */
                        this.isSiteSearchErrorAlertVisible = true;
                    }
                }
            );
        },

        rad: function (x) {
            return (x * Math.PI) / 180;
        },

        // ATTACH DATA AND MESSAGE TO MARKERS
        attachMarkerMessage: function (marker, message) {
            var infowindow = new window.google.maps.InfoWindow({
                content: message,
            });

            marker.infowindow = infowindow;

            marker.addListener("click", function () {
                infowindow.open(marker.get("map"), marker);
            });
        },
    },
};
</script>

<style lang="scss">
.map-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    @media only screen and (min-width: 1025px) {
        flex-direction: row;
    }

    .map-details-wrapper {
        // width: 100%;
        // background-color: rgba(38, 165, 221, 0.5);
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        padding: 16px;
        width: 100%;
        box-sizing: border-box;

        @media only screen and (min-width: 1025px) {
            width: 50%;
            padding: 16px;
        }

        .map-details {
            max-width: 500px;
        }

    }

    #map {
        height: 500px;
        width: 100%;

        @media only screen and (min-width: 1025px) {
            width: 50%;
        }
    }

    .gm-style-iw.gm-style-iw-c,
    .gm-style-iw-d {
        padding: 0 !important;
        overflow: hidden !important;
        border-radius: 2px;
    }

    div#content {
        width: 290px;
    }

    div#content #bodyContent {
        padding: 12px 16px;
    }

    div#content #bodyContent p {
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0.01em;
        text-align: left;
        margin-bottom: 4px;

        &:last-child {
            margin-bottom: 0;
        }
    }

    h2.gm-heading {
        background-color: $study-center-location-head-color;
        margin: 0;
        font-size: 16px;
        padding: 8px 24px 8px 16px;
        color: white;
        letter-spacing: 0.5px;
    }

    .gm-ui-hover-effect img {
        width: 25px !important;
        height: 25px !important;
        background-color: transparent;
    }

    button.gm-ui-hover-effect {
        right: 6px !important;
        top: -3px !important;

        &:focus {
            box-shadow: none;
        }
    }
}

.ui-sdc {
    border: 2px solid #dfdfdf;
    display: flex;
    padding: 4px;
    border-radius: 4px;
    justify-content: space-between;
    width: 96%;
    box-sizing: border-box;

    @media only screen and (max-width: 767px) {
        width: 100%;
    }

    .input-wrapper {
        flex: 1;

        @media only screen and (max-width: 767px) {}
    }

    &__input {
        input {
            margin: 0;
            border: none;
            box-shadow: none;
            font-size: 16px;
            background: none;

            @media only screen and (max-width: 1023px) {
                padding: 12px 2px;
            }

            &:focus {
                outline: none;
                border: none;
                box-shadow: none;
            }
        }
    }
}

.find-center-btn {

    background-color: $study-center-btn-color !important;

    &:hover {
        background-color: darken($study-center-btn-color, 10%) !important;
    }
}

.google-map {
    &.gmap-bg {
        background-image: url("../../assets/images/map-placeholder.png");
        background-repeat: no-repeat;
        background-position: 90%;
        background-size: cover;
    }
}

.map-overlay {
    position: absolute;
    height: 500px;
    width: 100%;
    background-color: rgba(0, 0, 0, .5);

    display: flex;
    align-items: center;

    &.ui-hide {
        visibility: hidden;
        opacity: 0;
        transition: all 0.09s;
    }
}

.map-overlay-wrapper {
    background: white;
    padding: 20px;
    border-radius: 5px;
    max-width: 400px;
    margin: auto;

    &__text {
        color: black;
        text-align: center;
    }
}
</style>
